import { lazy } from "react";
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { globalStorage } from "./helpers";

const MainLayout = lazy(() => import('./layouts'))
const LoginPage = lazy(() => import('./pages/login'));
const DashboardPage = lazy(() => import('./pages/dashboard'));
const DynamicMaster = lazy(() => import('./pages/dynamicMaster'))
const Menu = lazy(() => import('./pages/menu'))
const CreateOrganization = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/createOrganization'))
const EditOrganization = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/editOrganization'))
const MenuOrganization = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/menuOrganization'))
const CreateUpdateUnit = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/unit'))
const CreateUpdateUser = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/user'))
const CreateUpdateProfile = lazy(() => import('./pages/dynamicMaster/components/createOrUpdate/profile'))
const CreateUpdateMenu = lazy(() => import('./pages/menu/components/createOrUpdate'))

const RequireAuth = ({children}: {children: React.ReactNode}) => {
    const { pathname } = useLocation();
    const token = Cookies.get("admfin_access_token");
    const refreshToken = Cookies.get('admfin_refresh_token');
    if(!token){
        return <Navigate to="/login" state={{ prev: pathname}}  />
    } 

    if(refreshToken){
        try {
            const tokens:string | any  = Cookies.get('admfin_access_token');
            const expires = (tokens.expires_in || 30 * 60) * 1000
            const inOneHour = new Date(new Date().getTime() + expires)
            Cookies.set('admfin_access_token', tokens, { expires: inOneHour })
            Cookies.set('admfin_refresh_token', 'refresh_token')
            return children;
        } catch(e: any){
            Cookies.remove('admfin_access_token')
            globalStorage.removeItem('admfin_user_data')
            Cookies.remove('admfin_refresh_token')
            return <Navigate to="/login" state={{ prev: pathname}} />
        }
    }
    return children;
}

const Router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage />
    },
    {
        path: "/",
        element: <RequireAuth><MainLayout /></RequireAuth>,
        children: [
            { path: '/dashboard', element: <DashboardPage /> },
            { 
                path: '/organization', 
                element: <DynamicMaster 
                            pageName="Organization" 
                            apiList='admin/organization/list'
                            addRoute='/organization/create'
                            apiDelete="admin/organization/delete"
                        /> 
            },
            { path: '/organization/create', element: <CreateOrganization /> },
            { path: '/organization/edit/:id', element: <EditOrganization />},
            { path: '/organization/menu/:id', element: <MenuOrganization />},
            { 
                path: '/units', 
                element: <DynamicMaster 
                            pageName="Unit" 
                            apiList="admin/units/list"
                            apiDelete="admin/units/delete" 
                            addRoute="/units/create"
                            organizationFilter
                        /> 
            },
            { path: '/units/create', element: <CreateUpdateUnit /> },
            { path: '/units/edit/:id', element: <CreateUpdateUnit isEdit />},
            { 
                path: '/users', 
                element: <DynamicMaster 
                            pageName="User" 
                            apiList="admin/users/list"
                            apiDelete="admin/users/delete"
                            addRoute="/users/create"
                            organizationFilter
                        /> 
            },
            { path: '/users/create', element: <CreateUpdateUser /> },
            { path: '/users/edit/:id', element: <CreateUpdateUser isEdit /> },
            { 
                path: '/profile', 
                element: <DynamicMaster 
                            pageName="Profile" 
                            apiList="admin/profile/list"
                            apiDelete="admin/profile/delete"
                            addRoute="/profile/create"
                            organizationFilter
                        /> 
            },
            { path: '/profile/create', element: <CreateUpdateProfile /> },
            { path: '/profile/edit/:id', element: <CreateUpdateProfile isEdit /> },
            { 
                path: '/menu', 
                element: <Menu 
                            pageName="Menu" 
                            apiList="admin/settings/menu"
                            apiDelete="admin/settings/delete-menu"
                            addRoute="/menu/create"
                        /> 
            },
            { path: '/menu/create', element: <CreateUpdateMenu /> },
            { path: '/menu/edit/:id', element: <CreateUpdateMenu isEdit /> },
        ]
    },
])

export default Router;