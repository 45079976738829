import { RouterProvider } from "react-router-dom"
import Routes from "./Routes"
import { Toaster } from "react-hot-toast"
import { Suspense } from "react"
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <Suspense fallback="Loading...">
        <RouterProvider router={Routes} />
      </Suspense>
    </QueryClientProvider>
  )
}

export default App
