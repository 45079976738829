import { EncryptStorage } from "encrypt-storage";
import { toast } from "react-hot-toast";

const key = import.meta.env.VITE_KEY_STORAGE;
const API_LOCAL:string = import.meta.env.VITE_API_LOCAL;
const API_STAGE:string = import.meta.env.VITE_API_STAGE;
const API_PROD:string = import.meta.env.VITE_API_PROD;

interface ErrorResponseProps{
    response: any,
    alert: boolean
}
const handleErrorFromApi = ({
    response,
    alert
}:ErrorResponseProps) => {
    let message:string;
    message = '';
    if(!response?.response_desc){ // kondisi jika response status dari api 200 atau tidak mendapatkan response_desc dari api
        let status:string | undefined = response?.response_code?.toString();
        
        if(!status){ // kondisi jika tidak terdapat response_code dari api
            message = 'Oops! There is no response from server';
            if(alert){
                toast.error(message)
            }
            return true
        }

        let desc:string = response?.response_desc;
        if(parseInt(status.charAt(0)) !== 2){ // kondisi jika response_code dari api ternyata tidak 200 walaupun response status 200
            
            if(response?.response_data){ // konsisi terdapat response data
                return false;
            } 

            if(alert){
                toast.error(desc)
            }
            return true
        } 
    }

    message = response?.response_desc; // kondisi jika response dari api tidak 200
    if(message){
        let status:string = response?.response_code?.toString();
        if(response?.response_data){ // konsisi terdapat response data,
            return false;
        }

        if(parseInt(status.charAt(0)) !== 2){ // kondisi jika response_code dari api ternyata tidak 200 walaupun response status 200
            
            if(response?.response_data){ // konsisi terdapat response data
                return false;
            } 

            if(alert){
                toast.error(message)
            }
            return true
        }
    }
    
    return false
}

const globalStorage = new EncryptStorage(key, {
    storageType     : 'sessionStorage',
    encAlgorithm    : 'Rabbit'
})

const numberWithCommas = (number:number | null) => {
    return number?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const serverOptions = [
    {
        label: 'Server Local',
        value: API_LOCAL
    },
    {
        label: 'Server Stage',
        value: API_STAGE
    },
    {
        label: 'Server Prod',
        value: API_PROD
    }
]

export {
    handleErrorFromApi,
    globalStorage,
    numberWithCommas,
    serverOptions
}